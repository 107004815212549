import { Eye } from "components/icons/eye-icon";
import Card from "components/ui/cards/card";
import { Routes } from "config/routes";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useCommunicationMessagesGrid } from "service/hook/customer";
import Pagination from "components/ui/pagination";
import { PageSize } from "utils/constants";
import {
  CommunicationMessage,
  CommunicationMessagesListRequestModel,
  CommunicationMessagesListResponseModel,
} from "types/communicationMessageModels";
import { userStore } from "store";
import Loader from "components/ui/loader/loader";
import Table from "rc-table";

const MessagesView = () => {
  const { t } = useTranslation("common");
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);

  const [communicationList, setCommunicationList] = useState<
    CommunicationMessage[]
  >([]);
  const { user } = userStore((state) => state);

  const { mutate: mutateCommunicationMessage, isLoading } =
    useCommunicationMessagesGrid();

  const MessagesTableColumns = useMemo(
    () => [
      {
        title: t("text-message-id"),
        dataIndex: "communication_id",
        key: "communication_id",
        align: "center",
        ellipsis: true,
        className: "!text-sm",
        width: 80,
      },
      {
        title: t("text-business"),
        dataIndex: "business_name",
        key: "business_name",
        align: "left",
        ellipsis: true,
        className: "!text-sm",
        width: 110,
      },
      {
        title: t("text-title"),
        dataIndex: "title",
        key: "title",
        align: "left",
        ellipsis: true,
        className: "!text-sm",
        width: 210,
      },
      {
        title: t("text-message-create"),
        dataIndex: "_created_at",
        key: "_created_at",
        align: "center",
        className: "!text-sm",
        width: 160,
      },
      {
        title: t("text-unread-message"),
        dataIndex: "unread_count",
        key: "unread_count",
        align: "center",
        className: "!text-sm",
        width: 150,
      },
      {
        title: t("text-details"),
        dataIndex: "action",
        key: "action",
        align: "center",
        className: "!text-sm",
        width: 50,
        render: (value: any, row: CommunicationMessage) => (
          <Link
            to={Routes.messageDetails}
            state={{
              customer_email: row.customer_email,
              communication_id: row.communication_id,
              businessdetail_id: row.businessdetail_id,
              business_name: row.business_name,
            }}
            className="inline-block transition duration-200 text-body hover:text-accent-hover focus:text-accent-hover"
            title={t("text-details")}
          >
            <Eye width={20} />
          </Link>
        ),
      },
    ],
    [t]
  );

  const getCommunicationMessages = () => {
    const input: CommunicationMessagesListRequestModel = {
      page: page,
      size: PageSize,
      customer_email: user?.email ?? "",
    };

    mutateCommunicationMessage(input, {
      onSuccess: (successData: CommunicationMessagesListResponseModel) => {
        if (successData.final_result_code === "000") {
          setTotalCount(successData.total_count);
          setCommunicationList(successData.captured_data);
        }
      },
    });
  };

  useEffect(() => {
    getCommunicationMessages();
  }, []);

  const onPagination = (pageNumber: number) => {
    setPage(pageNumber);
  };

  return (
    <div className="mx-auto flex w-full max-w-7xl flex-col px-5 pb-20 md:flex-row md:pb-10 xl:px-8 xl:pb-14 2xl:px-14 mt-28">
      <Card className="self-stretch w-full min-h-screen overflow-hidden lg:min-h-0 mt">
        <div className="flex items-center justify-start mb-8 sm:mb-10">
          <div className="flex flex-col">
            <h1 className="text-lg font-semibold text-heading mb-4">
              {`${t("text-user")} / ${t("text-messages")}`}
            </h1>
            <div className="bg-cyan-100 px-7 py-4">
              <h3 className="!text-lg font-semibold text-center text-heading sm:text-xl">
                {t("text-message-page-description")}
              </h3>
            </div>
          </div>
        </div>
        <>
          {isLoading ? (
            <Loader
              className="!h-full flex-1"
              text={t("common:text-loading")}
            />
          ) : (
            <>
              <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
                <Table
                  /* @ts-ignore */
                  columns={MessagesTableColumns}
                  emptyText={t("table:empty-table-data")}
                  data={communicationList}
                  rowKey="communication_id"
                  scroll={{ x: 900 }}
                />
              </div>
              <div className="flex items-center justify-end">
                <Pagination
                  total={totalCount}
                  current={page}
                  pageSize={PageSize}
                  onChange={onPagination}
                  showLessItems
                />
              </div>
            </>
          )}
        </>
      </Card>
    </div>
  );
};

MessagesView.authenticationRequired = true;
export default MessagesView;
