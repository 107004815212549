import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useCustomerFinanceCreatePaymentIntent,
  useCustomerFinancePaymentConfig,
  useCustomerFinanceRetrievePaymentIntent,
} from "service/hook/customerFinance";
import { ConfigPaymentResponse } from "types/customerFinance.model";
import Button from "components/ui/button";
import { Routes } from "config/routes";
import { useTranslation } from "react-i18next";

const SuccessPaymentView = () => {
  // const stripe = useStripe();
  const { t } = useTranslation("common");

  // const elements = useElements();
  const navigate = useNavigate();

  const [publishKey, setPublishKey] = useState<string | undefined>();
  const [clientSecret, setClientSecret] = useState<string | undefined>();
  const [loadedStripe, setLoadedStripe] = useState<any>();

  const {
    mutate: mutateCustomerFinanceRetrievePaymentIntent,
    isLoading: isLoadingCustomerFinanceRetrievePaymentIntent,
    serverError: serverErrorCustomerFinanceRetrievePaymentIntent,
    setServerError: setServerErrorCustomerFinanceRetrievePaymentIntent,
  } = useCustomerFinanceRetrievePaymentIntent();

  const [retrieveResult, setRetrieveResult] = useState<any>();

  const {
    mutate: mutateCustomerFinancePaymentConfig,
    isLoading: isLoadingCustomerFinancePaymentConfig,
    serverError: serverErrorCustomerFinancePaymentConfig,
    setServerError: setServerErrorCustomerFinancePaymentConfig,
  } = useCustomerFinancePaymentConfig();

  const {
    mutate: mutateCustomerFinanceCreatePaymentIntent,
    isLoading: isLoadingCustomerFinanceCreatePaymentIntent,
    serverError: serverErrorCustomerFinanceCreatePaymentIntent,
    setServerError: setServerErrorCustomerFinanceCreatePaymentIntent,
  } = useCustomerFinanceCreatePaymentIntent();

  useEffect(() => {
    getPaymentConfig();
  }, []);

  useEffect(() => {
    if (publishKey && loadedStripe) retrievePaymentIntent();
  }, [publishKey, loadedStripe]);

  // useEffect(() => {
  //     if (clientSecret) retrievePaymentIntent();
  // }, [clientSecret]);

  const getPaymentConfig = async () => {
    mutateCustomerFinancePaymentConfig(undefined, {
      onSuccess: async (successData: ConfigPaymentResponse) => {
        setPublishKey(successData.publishableKey);

        const stripe = await loadStripe(successData.publishableKey ?? "");
        setLoadedStripe(stripe);
      },
      onError: async (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        toast.error(data?.general_result);
      },
    });
  };

  const retrievePaymentIntent = async () => {
    const searchParams = new URLSearchParams(window.location.href);
    const clientSecret = searchParams.get("payment_intent_client_secret");

    if (!clientSecret) navigate("/");

    setClientSecret(clientSecret ?? "");

    // return false;

    if (loadedStripe && clientSecret) {
      const { paymentIntent } = await loadedStripe?.retrievePaymentIntent(
        clientSecret ?? ""
      );

      setRetrieveResult(paymentIntent);
      toast.success(`status: ${paymentIntent?.status}`);
      console.log(paymentIntent);
    }

    // const retrieveInput: RetrievePaymentIntentReq = {
    //     intent_id: state.intentId ?? "",
    // };
    // await mutateCustomerFinanceRetrievePaymentIntent(retrieveInput, {
    //     onSuccess: async (successDataRetrieve: RetrievePaymentIntentResponse) => {
    //         if (successDataRetrieve.final_result_code === "000") {
    //             console.log(
    //                 "successDataRetrieve.captured_data => ",
    //                 successDataRetrieve.captured_data
    //             );

    //             setRetrieveResult(successDataRetrieve);
    //             toast.success(successDataRetrieve?.general_result ?? "");
    //         } else {
    //             toast.error(successDataRetrieve.general_result);
    //         }
    //     },
    //     onError: async (error: Error) => {
    //         const {
    //             response: { data },
    //         }: any = error ?? {};
    //         toast.error(data?.general_result);
    //     },
    // });
  };

  return (
    <div className="w-full bg-slate-300 align-center justify-center text-center py-20">
      <span className="">Payment result : </span>
      {
        <span className="">
          {isLoadingCustomerFinanceRetrievePaymentIntent
            ? "Loading"
            : retrieveResult?.status ?? "unknown"}
        </span>
      }
      <br />
      <Button
        className="bg-teal-500 text-white"
        onClick={() => {
          window.location.href = localStorage.getItem("business-url") ?? "/";
        }}
      >
        {t("text-home")} {t("text-page")}
      </Button>
    </div>
    // <Elements stripe={getStripe()} options={options}>
    // </Elements>
  );
};

export default SuccessPaymentView;
