import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import Logo from "../ui/logo";
import JoinButton from "../layouts/menu/join-button";
import { useTranslation } from "react-i18next";
import AuthorizedMenu from "../layouts/menu/authorized-menu";
import { authStore, userStore } from "../../store";
import StaticMenu from "../layouts/menu/static-menu";
import GroupsDropdownMenu from "../layouts/menu/groups-menu";
import pubStore from "../../store/pubStore";
import {
  MarketPlaceShowItem,
  Type,
  UserMarketPlaceUpdateReq,
  UserMarketPlaceUpdateResponse,
} from "../../types";
import {
  AuFlag,
  CaFlag,
  DeFlag,
  FrFlag,
  GbFlag,
  IlFlag,
  UsFlag,
} from "../icons/flags";
import { useUserUpdateMarketPlace } from "../../service/hook/user";
import { toast } from "react-toastify";

export const GroupsItems = [
  {
    id: "1",
    name: "Fashion,Clothing,Accessory",
    // slug?: any;
    icon: "DressIcon",
  },
  {
    id: "2",
    name: "Health & Beauty",
    // slug: "health",
    icon: "MedicineIcon",
  },
  {
    id: "3",
    name: "Electronic,Computer",
    // slug?: any;
    icon: "FurnitureIcon",
  },
  {
    id: "4",
    name: "Food,Grocery",
    // slug?: any;
    icon: "FruitsVegetable",
  },
  {
    id: "5",
    name: "Home & Garden,PET,DIY",
    // slug?: any;
    icon: "BookIcon",
  },
  {
    id: "6",
    name: "Toy,children,baby",
    // slug?: any;
    icon: "Restaurant",
  },
  {
    id: "7",
    name: "Office products,furniture",
    // slug?: any;
    icon: "FurnitureIcon",
  },
  {
    id: "8",
    name: "Industrial tools",
    // slug?: any;
    icon: "FurnitureIcon",
  },
];

const getFlagIcon = (tag: string) => {
  switch (tag) {
    case "aus":
      return AuFlag;
    case "gbr":
      return GbFlag;
    case "can":
      return CaFlag;
    case "deu":
      return DeFlag;
    case "fra":
      return FrFlag;
    case "usa":
      return UsFlag;
    case "irl":
      return IlFlag;
    default:
      <></>;
      break;
  }
};

const Header = ({ layout }: { layout?: string }) => {
  const { t } = useTranslation("common");
  const { isAuthorized } = authStore((state) => state);
  const { user, marketPlaceItem } = userStore((state) => state);
  const { marketPlaceItems } = pubStore((state) => state);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [mPItems, setMPItems] = useState<Type[]>([]);

  const isFlattenHeader = false;

  const closeLocation = () => setOpenDropdown(false);

  const {
    mutate: mutateMarketPlaceUpdate,
    isLoading: isLoadingMarketPlaceUpdate,
    serverError: serverErrorMarketPlaceUpdate,
    setServerError: setServerErrorMarketPlaceUpdate,
  } = useUserUpdateMarketPlace();

  useEffect(() => {
    if (marketPlaceItems.length > 0) {
      setMPItems(
        marketPlaceItems.map((mapItem, index) => {
          const item: Type = {
            id: mapItem.marketplace_id,
            name: mapItem.marketplace_name,
            icon: getFlagIcon(mapItem.marketplace_id.toLowerCase()),
          };

          return item;
        })
      );
    }
  }, [marketPlaceItems]);

  const updateUserMarketPlace = (item: MarketPlaceShowItem) => {
    const input: UserMarketPlaceUpdateReq = {
      email: user?.email ?? "",
      marketplaceId: item.marketplace_id,
      currency_code: marketPlaceItem ? marketPlaceItem.marketplace_id : "GBR",
    };

    mutateMarketPlaceUpdate(input, {
      onSuccess: (successData: UserMarketPlaceUpdateResponse) => {
        if (successData.final_result_code === "000") {
          toast.success(successData.general_result);
        } else {
          toast.error(successData.general_result);
        }
      },
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        toast.error(data?.general_result);

        if (data?.final_result_code === "001") {
          toast.error(data?.general_result[0]);
        }
      },
    });
  };

  return (
    <header
      className={cn("site-header-with-search top-0 z-50 w-full lg:h-22", {
        "": isFlattenHeader,
        "sticky lg:fixed": true,
        "sticky border-b border-border-200 shadow-sm": !true,
      })}
    >
      <div
        className={cn(
          "fixed inset-0 -z-10 h-[100vh] w-full bg-black/50",
          openDropdown === true ? "" : "hidden"
        )}
        onClick={closeLocation}
      ></div>
      <div>
        <div
          className={cn(
            " flex w-full transform-gpu items-center justify-between bg-light transition-transform duration-300 lg:h-22 lg:px-4 xl:px-8",
            {
              "lg:absolute lg:border-0 lg:bg-transparent lg:shadow-none":
                isFlattenHeader,
              "lg:!bg-light": openDropdown,
            }
          )}
        >
          <div className="flex w-full shrink-0 grow-0 basis-auto flex-col items-center lg:w-auto lg:flex-row">
            <Logo
              className={cn(
                "pt-2 pb-3",
                !true ? "lg:mx-0" : "ltr:ml-0 rtl:mr-0"
              )}
            />

            {/* <div className="ltr:ml-10 ltr:mr-auto rtl:mr-10 rtl:ml-auto xl:block ml-4">
                            <GroupsDropdownMenu variant={"colored"} />
                        </div> */}
            <div className="hidden ltr:ml-10 ltr:mr-auto rtl:mr-10 rtl:ml-auto xl:block ml-4">
              <GroupsDropdownMenu
                variant={"colored"}
                groups={GroupsItems}
                defaultGroup={GroupsItems[0]}
              />
            </div>
          </div>
          <ul className="hidden shrink-0 items-center space-x-7 rtl:space-x-reverse lg:flex 2xl:space-x-10">
            <StaticMenu />
            <div className="flex items-center space-x-4 rtl:space-x-reverse">
              {isAuthorized && (
                <GroupsDropdownMenu
                  variant={"colored"}
                  groups={mPItems}
                  defaultGroup={mPItems.find(
                    (findItem) => findItem.id === "GBR"
                  )}
                  className="xl:min-w-fit"
                  showIcon={true}
                  onItemClick={(item: Type) => {
                    updateUserMarketPlace({
                      marketplace_id: item.id,
                      marketplace_name: item.name,
                    });
                  }}
                />
              )}

              <a
                href={`${process.env.REACT_APP_ADMIN_URL}`}
                target="_blank"
                rel="noreferrer"
                className="inline-flex h-8 shrink-0 items-center justify-center rounded border border-transparent bg-accent px-3 py-0 text-sm font-semibold leading-none text-light outline-none transition duration-300 ease-in-out hover:bg-accent-hover focus:shadow focus:outline-none focus:ring-1 focus:ring-accent-700"
              >
                {t("text-become-seller")}
              </a>
              <li
                style={{
                  direction: "rtl",
                }}
              >
                {isAuthorized ? (
                  user?.isConfirmEmail ? (
                    <AuthorizedMenu minimal={true} />
                  ) : (
                    <></>
                  )
                ) : (
                  <JoinButton />
                )}
              </li>
            </div>
          </ul>
        </div>
        <div
          className={cn(
            "w-full border-b border-t border-border-200 bg-light shadow-sm",
            true ? "hidden lg:block" : "hidden"
          )}
        ></div>
      </div>
    </header>
  );
};

export default Header;
