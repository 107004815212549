import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import client from "../client";

export function useBusinessProfileServicesById() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.customer.getBusinessProfileServicesById,
    {
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessProfile() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.customer.getBusinessProfileByWebUrl,
    {
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useProductCategoryShowName() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.customer.productCategoryShowName,
    {
      onSuccess: (data) => {},
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          // toast.error(`${data?.email}`);
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useProductSearchGridShow() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.customer.productSearchGridShow,
    {
      onSuccess: (data) => {},
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          // toast.error(`${data?.email}`);
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useMarketPlaceShow() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.customer.marketPlaceShow, {
    onSuccess: (data) => {},
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        // toast.error(`${data?.email}`);
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useCustomerContactUs() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.customer.contactUs, {
    onSuccess: (data) => {},
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        // toast.error(`${data?.email}`);
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useCommunicationMessagesGrid() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.customer.getCommunicationMessagesList,
    {
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          // toast.error(`${data?.email}`);
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}
export function useSendMessage() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.customer.sendMessage, {
    onSuccess: (data) => {},
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        // toast.error(`${data?.email}`);
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useMessageDetails() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.customer.getMessagesDetails,
    {
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          // toast.error(`${data?.email}`);
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useSendReplyMessage() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.customer.sendMessageReply, {
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        // toast.error(`${data?.email}`);
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessBranches() {
  const { mutate, isLoading } = useMutation(
    client.customer.getBusinessBranches
  );

  return { mutate, isLoading };
}

export function useSellerOverallScore() {
  const { mutate, isLoading } = useMutation(
    client.customer.getSellerOverallScore
  );

  return { mutate, isLoading };
}

export function useSellerReviewsList() {
  const { mutate, isLoading } = useMutation(client.customer.getSellerReviews);

  return { mutate, isLoading };
}

export function useSellerReviewScoreSubmit() {
  const { mutate, isLoading } = useMutation(
    client.customer.sellerReviewScoreSubmit
  );
  return { mutate, isLoading };
}

export function useFinancialTransactionsList() {
  const { mutate, isLoading } = useMutation(
    client.customer.getFinancialTransactionsList
  );
  return { mutate, isLoading };
}
