import { CUSTOMER } from "utils/constants";

export const API_ENDPOINTS = {
  PRODUCTS: "/products",
  PRODUCTS_POPULAR: "/popular-products",
  PRODUCTS_REVIEWS: "/reviews",
  PRODUCTS_REVIEWS_ABUSE_REPORT: "/abusive_reports",
  PRODUCTS_QUESTIONS: "/questions",
  FEEDBACK: "/feedbacks",
  CATEGORIES: "/categories",
  TYPES: "/types",
  TAGS: "/tags",
  SHOPS: "/shops",
  AUTHORS: "/authors",
  AUTHORS_TOP: "/top-authors",
  MANUFACTURERS: "/manufacturers",
  MANUFACTURERS_TOP: "/top-manufacturers",
  COUPONS: "/coupons",
  COUPONS_VERIFY: "/coupons/verify",
  ORDERS: "/orders",
  ORDERS_REFUNDS: "/refunds",
  ORDERS_PAYMENT: "/orders/payment",
  ORDERS_CHECKOUT_VERIFY: "/orders/checkout/verify",
  ORDERS_DOWNLOADS: "/downloads",
  GENERATE_DOWNLOADABLE_PRODUCT_LINK: "/downloads/digital_file",
  USERS: "/users",
  USERS_ADDRESS: "/address",
  USERS_ME: "/user/profile_show/",

  USERS_LOGIN: "/user/token-auth/",

  USERS_REGISTER: "/user/register/",

  USERS_FORGOT_PASSWORD: "/user/reset_password_request_token/",

  USERS_VERIFY_FORGOT_PASSWORD_TOKEN: "/verify-forget-password-token",
  USERS_RESET_PASSWORD: "/user/reset_password_update_pwd_by_token/",

  USERS_CHANGE_PASSWORD: "/change-password",
  USERS_LOGOUT: "/logout",
  USERS_SUBSCRIBE_TO_NEWSLETTER: "/subscribe-to-newsletter",
  USERS_CONTACT_US: "/customer/cif_contact_us_sending_message_create/",
  USERS_WISHLIST: "/my-wishlists",
  WISHLIST: "/wishlists",
  USERS_WISHLIST_TOGGLE: "/wishlists/toggle",
  SOCIAL_LOGIN: "/social-login-token",
  SEND_OTP_CODE: "/send-otp-code",
  VERIFY_OTP_CODE: "/user/verify_email/",
  USER_MARKET_PLACE_STATUS_UPDATE: "/user/user_profile_additional_update/",
  USER_MARKET_PLACE_STATUS_STATUS: "/user/user_profile_additional_show/",

  CUSTOMER_PRODUCT_CATEGORY_NAME_SHOW: "/customer/product_category_name_show/",
  CUSTOMER_PRODUCT_SEARCH_GRID_SHOW: "/customer/Product_Search_grid_show/",
  CUSTOMER_MARKET_PLACE_SHOW: "/customer/marketplace_show/",
  CUSTOMER_BUSINESS_PROFILE_BY_WEB_URL:
    "/business/business_profile_by_web_url_show/",
  CUSTOMER_BUSINESS_PROFILE_SERVICES_BY_ID:
    "/business/business_in_person_services_by_id_show/",
  CUSTOMER_COMMUNICATION_MESSAGES_LIST:
    "/business/communication_messages_grid_show/",
  CUSTOMER_COMMUNICATION_SEND_MESSAGES:
    "/business/communication_send_initial_message/",
  CUSTOMER_COMMUNICATION_MESSAGES_DETAILS:
    "/business/communication_customer_read_message_by_id_show/",
  CUSTOMER_COMMUNICATION_MESSAGES_REPLY:
    "/business/communication_send_response_message/",
  CUSTOMER_BUSINESS_BRANCHES: "/business/business_branch_location_view_show/",
  CUSTOMER_SELLER_OVERALL_SCORE: "/business/seller_overall_score_show/",
  CUSTOMER_SELLER_REVIEWS: "/business/seller_review_show/",
  CUSTOMER_SELLER_REVIEW_SCORE_SUBMIT: "/business/seller_review_score_submit/",

  OTP_LOGIN: "/otp-login",
  UPDATE_CONTACT: "",
  SETTINGS: "/settings",
  UPLOADS: "/attachments",
  MY_QUESTIONS: "/my-questions",
  MY_REPORTS: "/my-reports",
  CARDS: "/cards",
  SET_DEFAULT_CARD: "/set-default-card",
  SAVE_PAYMENT_METHOD: "/save-payment-method",
  PAYMENT_INTENT: "/payment-intent",
  BEST_SELLING_PRODUCTS: "/best-selling-products",
  SEND_VERIFICATION_EMAIL: "/email/verification-notification",
  USERS_UPDATE_EMAIL: "/update-email",
  STORE_NOTICES: "store-notices",
  NEAR_SHOPS: "/near-by-shop",

  CUSTOMER_FINANCE_BANK_CARD_CREATE: "/customer_finance/bank_card_create/",
  CUSTOMER_FINANCE_BANK_CARD_LIST: "/customer_finance/bank_card_show/",
  CUSTOMER_FINANCE_BANK_CARD_DELETE: "/customer_finance/bank_card_del/",
  CUSTOMER_FINANCE_BANK_ACCOUNT_CREATE:
    "/customer_finance/business_connected_account_create/",
  CUSTOMER_FINANCE_BANK_ACCOUNT_LIST:
    "/customer_finance/business_connected_account_show/",
  CUSTOMER_FINANCE_BANK_ACCOUNT_DELETE:
    "/customer_finance/business_connected_account_del/",
  CUSTOMER_FINANCE_CREATE_PAYMENT_INTENT:
    "/customer_finance/create_payment_intent/",
  CUSTOMER_FINANCE_CONFIRM_PAYMENT_INTENT:
    "/customer_finance/confirm_payment_intent/",
  CUSTOMER_FINANCE_RETRIEVE_PAYMENT_INTENT:
    "/customer_finance/retrieve_payment_intent/",
  CUSTOMER_FINANCE_PAYMENT_CONFIG: "/customer_finance/config/",
  CUSTOMER_SERVICE_BOOKING_SLOTS: "/business/service_booking_slots_grid_show/",
  CUSTOMER_SERVICE_BOOKING_SLOTS_DETAILS:
    "/business/service_booking_slots_details_show/",
  CUSTOMER_SERVICE_BOOKING_SLOTS_CANCEL:
    "/business/cancel_booking_slot_or_day_by_customer/",
  CUSTOMER_SERVICE_BOOKING_FREE_SLOTS:
    "/business/service_booking_free_slots_show/",
  CUSTOMER_BOOKING_SLOT_FOR_CUSTOMER: "/business/book_slot_for_customer/",
  CUSTOMER_FINANCIAL_TRANSACTIONS_LIST:
    "/customer_finance/customer_transactions_show/",
};
