export const PaymentIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50.000000 50.000000"
      {...props}
    >
      <g
        transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M175 400 c-3 -5 -13 -10 -21 -10 -16 0 -154 -100 -154 -112 0 -16 19
-7 83 42 36 28 69 50 72 50 12 0 5 -128 -7 -133 -7 -2 -10 -8 -6 -12 4 -5 39
6 78 24 38 18 74 30 80 26 19 -12 10 -36 -21 -53 -17 -9 -50 -32 -72 -49 -26
-20 -54 -33 -72 -33 -56 0 -135 -13 -135 -21 0 -11 94 -11 143 0 23 6 39 5 43
-1 3 -5 16 -8 27 -6 12 1 27 -1 34 -5 6 -4 31 -4 54 -1 32 5 46 14 58 35 14
26 21 29 68 29 66 0 73 12 73 120 0 124 6 120 -170 120 -88 0 -151 -4 -155
-10z m303 -27 c3 -10 -31 -13 -147 -13 -139 0 -164 4 -144 24 4 3 70 5 147 4
100 -2 141 -6 144 -15z m0 -115 l-3 -63 -105 -3 -105 -2 32 20 c37 22 44 58
17 81 -16 13 -27 10 -116 -26 -15 -6 -18 -2 -18 24 l0 31 150 0 151 0 -3 -62z
m-242 -104 c-3 -9 -6 -18 -6 -20 0 -2 -7 -4 -15 -4 -18 0 -19 12 -3 28 17 17
31 15 24 -4z m56 -9 c-4 -25 -26 -34 -37 -15 -11 17 3 40 23 40 15 0 18 -5 14
-25z m58 17 c0 -10 -22 -32 -32 -32 -4 0 -8 9 -8 20 0 13 7 20 20 20 11 0 20
-4 20 -8z"
        />
      </g>
    </svg>
  );
};
