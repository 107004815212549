import PublicIcon from "@mui/icons-material/Public";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import cn from "classnames";
import Modal from "components/modal/modal";
import Button from "components/ui/button";
import Input from "components/ui/forms/input";
import TextArea from "components/ui/forms/text-area";
import Loader from "components/ui/loader/loader";
import { Routes as routesPath } from "config/routes";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useBookSlotFormCustomer,
  useServiceBookingFreeSlots,
} from "service/hook/serviceBooking.hook";
import { userStore } from "store";
import { MainResponse } from "types";
import {
  BusinessProfileDetails,
  BusinessProfileResponseModel,
} from "types/business-profile-models";
import {
  ServiceBookFreeSlotsResponse,
  ServiceBookingSlot,
} from "types/serviceBooking.models";

interface IProps {
  businessProfileData: BusinessProfileResponseModel;
  serviceDetails?: BusinessProfileDetails;
  showMainPage: () => void;
}

const ServiceBook: FC<IProps> = ({
  businessProfileData,
  serviceDetails,
  showMainPage,
}) => {
  const { t } = useTranslation("common");
  const { user } = userStore((state) => state);
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState<any>(moment(new Date()));
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [selectedBookSlot, setSelectedBookSlot] =
    useState<ServiceBookingSlot>();

  const [serviceBookingSlots, setServiceBookingSlots] = useState<
    ServiceBookingSlot[]
  >([]);

  const { mutate: mutateServiceBookingFreeSlots, isLoading } =
    useServiceBookingFreeSlots();

  const {
    mutate: mutateBookSlotFormCustomer,
    isLoading: loadingBookSlotFormCustomer,
  } = useBookSlotFormCustomer();

  const getServiceBookFreeSlots = () => {
    mutateServiceBookingFreeSlots(
      {
        businessdetail_id: `${businessProfileData.businessdetail_id}`,
        marketplaceId: "GBR",
        start_date: moment(selectedDate).format("DD-MM-YYYY"),
        end_date: moment(selectedDate).format("DD-MM-YYYY"),
      },
      {
        onSuccess: (successData: ServiceBookFreeSlotsResponse) => {
          if (successData.final_result_code === "000") {
            setServiceBookingSlots(successData.captured_data);
          }
        },
      }
    );
  };

  useEffect(() => {
    getServiceBookFreeSlots();
  }, [selectedDate]);

  const selectBookDate = (serviceBookSlot: ServiceBookingSlot) => {
    setSelectedBookSlot(serviceBookSlot);
    setShowConfirmDialog(true);
  };

  const handleSelectBookSlot = () => {
    mutateBookSlotFormCustomer(
      {
        businessdetail_id: `${businessProfileData.businessdetail_id}`,
        marketplaceId: "GBR",
        customer_email: user?.email ?? "",
        service_booking_record_id:
          selectedBookSlot?.service_booking_record_id || 0,
        service_id_ordered: `${serviceDetails?.binp_srv_id}`,
        service_description_ordered: serviceDetails?.service_title,
      },
      {
        onSuccess: (successData: MainResponse) => {
          if (successData.final_result_code === "000") {
            toast.success(successData.general_result);
            resetPage();
            showMainPage();
          } else {
            toast.error(successData.general_result);
          }
        },
      }
    );
  };

  const resetPage = () => {
    setShowConfirmDialog(false);
    setSelectedBookSlot(undefined);
  };

  const closeModal = () => {
    resetPage();
  };
  return (
    <>
      <div className="flex w-[90%] mx-auto flex-col px-5 pb-5 md:flex-row xl:px-8 2xl:px-14">
        <div className="flex h-full flex-col w-full">
          <div className="flex items-center justify-start mb-8 sm:mb-10 mt-5 px-5">
            <div className="flex flex-col">
              <h1 className="text-lg font-semibold text-heading mb-4">
                {`${t("text-service-book")}`}
              </h1>
            </div>
          </div>
          <div className="flex w-full">
            <div className="flex flex-col w-[35%]">
              <img
                src={businessProfileData.business_logo.file_urls?.[0]?.url}
                alt="Business Logo"
                width={150}
                height={150}
                className="mb-5"
              />
              <div className="font-bold text-2xl my-5">
                {serviceDetails?.service_title}
              </div>
              <div className="mt-5">{t("text-description-service-book")}</div>
            </div>
            <div className="w-[65%]">
              <div className="flex flex-col w-full">
                <div className="flex items-center justify-center gap-10">
                  <div className="font-bold pb-10 text-center">
                    Select a Date & Time
                  </div>
                  <div className="text-lg pb-10">
                    {moment(selectedDate).format("YYYY-MM-DD")}
                  </div>
                </div>

                <div className="flex flex-row w-full">
                  <div className="flex flex-col gap-10 w-[45%]">
                    <div className="w-full shadow-500">
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateCalendar
                          value={selectedDate}
                          onChange={(date: any) => setSelectedDate(date)}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="flex flex-col gap-4">
                      <div className="font-bold">Time zone</div>
                      <div className="flex gap-3">
                        <PublicIcon className="ml-5 w-[15px]" />
                        <span>UK, Ireland (GMT)</span>
                      </div>
                    </div>
                  </div>

                  <div className="w-[65%] flex flex-col items-center gap-4">
                    {isLoading ? (
                      <Loader
                        className="flex-1"
                        text={t("common:text-loading")}
                      />
                    ) : serviceBookingSlots.length === 0 ? (
                      <div className="w-full px-4">
                        <div className="p-2 bg-cyan-100 rounded-md">
                          No Time this date
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col gap-2 px-4 w-1/2 overflow-y-auto h-[335px]">
                        {serviceBookingSlots.map((item) => (
                          <div
                            className={cn(
                              "rounded-md border-solid border-2 border-blue-200 border-sm text-blue-500 text-center py-2 cursor-pointer hover:text-blue-700 hover:border-blue-400 focus:text-blue-700 focus:border-blue-400",
                              selectedBookSlot?.service_booking_record_id ==
                                item.service_booking_record_id
                                ? "bg-blue-500 text-white border-blue-500"
                                : ""
                            )}
                            onClick={() => {
                              resetPage();
                              selectBookDate(item);
                            }}
                          >
                            <span>
                              {item.booking_times.split(" ").join("-")}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={showConfirmDialog} onClose={closeModal}>
        <div className="m-auto w-[600px] max-w-6xl rounded-md bg-light p-4 pb-6">
          <div className="h-full w-full text-center">
            <div className="flex h-full flex-col justify-between">
              <p className="py-2 px-6 leading-relaxed my-3">
                {"Please confirm if you'd like to choose this time?"}
              </p>
              <div className="flex flex-col mb-4 gap-1">
                <div className="flex gap-4">
                  <span className="font-bold">Selected date: </span>
                  <span>{moment(selectedDate).format("YYYY-MM-DD")}</span>
                </div>
                <div className="flex gap-4">
                  <span className="font-bold">Selected time: </span>
                  <span>{selectedBookSlot?.booking_times}</span>
                </div>
                <div className="flex gap-4">
                  <span className="font-bold">Service: </span>
                  <span>{serviceDetails?.service_title}</span>
                </div>
              </div>

              <div className="space-s-4 mt-5 flex w-full items-center justify-between">
                <div className="w-1/2">
                  <Button
                    onClick={closeModal}
                    variant="custom"
                    className={cn(
                      "!w-36 rounded bg-amber-400 hover:bg-amber-500 focus:bg-amber-500 py-2 px-4 text-center text-base font-semibold text-light shadow-md transition duration-200 ease-in focus:outline-none"
                    )}
                  >
                    {t("text-no")}
                  </Button>
                </div>

                <div className="w-1/2">
                  <Button
                    onClick={handleSelectBookSlot}
                    variant="custom"
                    className={cn(
                      "!w-36 rounded  py-2 px-4 text-center text-base font-semibold text-light shadow-md transition duration-200 ease-in focus:outline-none",
                      "bg-accent hover:bg-accent-hover focus:bg-accent-hover focus:text-light"
                    )}
                  >
                    {t("text-yes")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ServiceBook;
