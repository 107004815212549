import { CloseIcon } from "components/icons/close-icon";
import { Eye } from "components/icons/eye-icon";
import { PaymentIcon } from "components/icons/payment";
import Button from "components/ui/button";
import Card from "components/ui/cards/card";
import Label from "components/ui/label";
import Loader from "components/ui/loader/loader";
import Pagination from "components/ui/pagination";
import moment from "moment";
import Table from "rc-table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useServiceBookingSlotsList } from "service/hook/serviceBooking.hook";
import { userStore } from "store";
import {
  ServiceBookingSlot,
  ServiceBookingSlotsRequest,
  ServiceBookingSlotsResponse,
} from "types/serviceBooking.models";
import { PageSize } from "utils/constants";
import { useModalAction } from "provider/modal.context";
import { businessKey } from "components/layouts/customer/BusinessLayout2/CustomerBusinessLayout2";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "components/ui/date-picker";
import { useBusinessProfileServicesById } from "service/hook/customer";
import { BusinessProfileServicesDetailsResponseModel } from "types/business-profile-models";
import { Routes as routesPath } from "config/routes";

const ServiceBookingView = () => {
  const { t } = useTranslation("common");
  const { openModal } = useModalAction();
  const navigate = useNavigate();

  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [serviceBookingSlots, setServiceBookingSlots] = useState<
    ServiceBookingSlot[]
  >([]);

  const { user } = userStore((state) => state);

  const { mutate: mutateServiceBookingSlotsList, isLoading } =
    useServiceBookingSlotsList();
  const { mutate: businessProfileServicesMutate } =
    useBusinessProfileServicesById();

  const getServiceDetails = (item: ServiceBookingSlot) => {
    businessProfileServicesMutate(
      {
        binp_srv_id: item.service_id_ordered ?? "",
        businessdetail_id: `${item.businessdetail_id}`,
      },
      {
        onSuccess: (
          successData: BusinessProfileServicesDetailsResponseModel
        ) => {
          if (successData.final_result_code === "000") {
            const serviceDetails = successData.captured_data;
            navigate(routesPath.payment, {
              state: {
                currency: serviceDetails.currency,
                price: serviceDetails.price,
                businessDetailId: `${item.businessdetail_id}`,
              },
            });
          }
        },
      }
    );
  };

  const columns: any[] = [
    {
      title: t("table:table-record-id"),
      dataIndex: "service_booking_record_id",
      key: "service_booking_record_id",
      width: 90,
      align: "center",
    },
    {
      title: t("table:table-book-date"),
      dataIndex: "booking_date",
      key: "booking_date",
      width: 100,
      align: "center",
      render: (value: string) => {
        const date = value.split("-").reverse().join("-");
        return <div>{date}</div>;
      },
    },
    {
      title: t("table:table-time"),
      dataIndex: "booking_times",
      key: "booking_times",
      width: 120,
      align: "center",
      render: (value: string) => {
        const date = value.split(" ").join(",");
        return <div>{date}</div>;
      },
    },
    {
      title: t("table:table-item-action"),
      className: "cursor-pointer",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 80,
      render: function Render(value: number, record: ServiceBookingSlot) {
        return (
          <div
            className="space-x-4"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <div className="flex gap-2 justify-start"> */}
            <button
              onClick={() => {
                openModal("SERVICE_BOOKING_SLOT_DETAILS", {
                  item: record,
                });
              }}
              className="inline-block transition duration-200 text-body hover:text-accent-hover focus:text-accent-hover"
              title={t("text-details")}
            >
              <Eye width={20} />
            </button>
            <button
              onClick={() => {
                localStorage.setItem(
                  businessKey,
                  `${window.location.origin}${window.location.pathname}`
                );
                getServiceDetails(record);
              }}
              className="inline-block transition duration-200 text-body hover:text-accent-hover focus:text-accent-hover"
              title={t("common:text-payment")}
            >
              <PaymentIcon width={20} height={20} />
            </button>
            <button
              onClick={() => {
                openModal("SERVICE_BOOKING_SLOT_CANCEL", {
                  item: record,
                  callback: () => {
                    getServiceBookingSlotsList(1);
                  },
                });
              }}
              className="inline-block transition duration-200 text-body hover:text-accent-hover focus:text-accent-hover"
              title={t("common:text-cancel")}
            >
              <CloseIcon width={20} height={20} />
            </button>
          </div>
        );
      },
    },
  ];

  const getServiceBookingSlotsList = (pageNumber?: number) => {
    const input: ServiceBookingSlotsRequest = {
      page: pageNumber ?? page,
      size: PageSize,
      customer_email: user?.email ?? "",
      marketplaceId: "GBR",
      start_date: startDate
        ? moment(startDate).format("DD-MM-YYYY")
        : undefined,
      end_date: endDate ? moment(endDate).format("DD-MM-YYYY") : undefined,
    };

    mutateServiceBookingSlotsList(input, {
      onSuccess: (successData: ServiceBookingSlotsResponse) => {
        if (successData.final_result_code === "000") {
          setTotalCount(successData.total_count);
          setServiceBookingSlots(successData.captured_data);
        }
      },
    });
  };

  useEffect(() => {
    getServiceBookingSlotsList();
  }, []);

  const onPagination = (pageNumber: number) => {
    setPage(pageNumber);
  };

  return (
    <div className="mx-auto flex w-full max-w-7xl flex-col px-5 pb-20 md:flex-row md:pb-10 xl:px-8 xl:pb-14 2xl:px-14 mt-28">
      <Card className="self-stretch w-full min-h-screen overflow-hidden lg:min-h-0 mt">
        <div className="flex items-center justify-start mb-8 sm:mb-10">
          <div className="flex flex-col">
            <h1 className="text-lg font-semibold text-heading mb-4">
              {`${t("text-user")} / ${t("text-service-booking")}`}
            </h1>
            <div className="bg-cyan-100 px-7 py-4">
              <div className="flex flex-col gap-2">
                <h3 className="!text-lg font-semibold text-heading sm:text-xl">
                  {t("text-service-booking-description1")}
                </h3>
                <h3 className="!text-lg font-semibold text-heading sm:text-xl">
                  {t("text-service-booking-description2")}
                </h3>
                <h3 className="!text-lg font-semibold text-heading sm:text-xl">
                  {t("text-service-booking-description3")}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full p-4">
          <div className="flex gap-4 w-full">
            <div className="w-1/2">
              <Label className="text-left">{"Start date"}</Label>
              <DatePicker
                className="w-full"
                selected={startDate}
                onChange={(date: any) => {
                  setStartDate(date);
                }}
                showYearDropdown
                showMonthDropdown
                dateFormat="yyy-MM-dd"
              />
            </div>
            <div className="w-1/2">
              <Label className="text-left">{"End date"}</Label>
              <DatePicker
                className="w-full"
                selected={endDate}
                onChange={(date: any) => {
                  setEndDate(date);
                }}
                showYearDropdown
                showMonthDropdown
                dateFormat="yyy-MM-dd"
              />
            </div>
          </div>
        </div>

        <div
          className="mb-4 text-start px-4"
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <div
            className="mb-4 text-start"
            style={{
              display: "flex",
              justifyContent: "start",
            }}
          >
            <Button
              onClick={() => {
                getServiceBookingSlotsList(1);
              }}
              loading={isLoading}
              disabled={isLoading}
            >
              {"Search"}
            </Button>
          </div>
        </div>
        <>
          {isLoading ? (
            <Loader
              className="!h-full flex-1"
              text={t("common:text-loading")}
            />
          ) : (
            <>
              <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
                <Table
                  columns={columns}
                  emptyText={t("table:empty-table-data")}
                  data={serviceBookingSlots}
                  rowKey="service_booking_record_id"
                  scroll={{ x: 900 }}
                />
              </div>
              <div className="flex items-center justify-end">
                <Pagination
                  total={totalCount}
                  current={page}
                  pageSize={PageSize}
                  onChange={onPagination}
                  showLessItems
                />
              </div>
            </>
          )}
        </>
      </Card>
    </div>
  );
};

ServiceBookingView.authenticationRequired = true;
export default ServiceBookingView;
