import {
  BusinessProfileRequestModel,
  BusinessProfileResponseModel,
  BusinessProfileServicesDetailsResponseModel,
  BusinessProfileServicesRequestModel,
  SellerOverallScoreRequest,
  SellerReviewScoreRequest,
  SellerReviewsListRequest,
} from "types/business-profile-models";
import type {
  AuthResponse,
  ChangePasswordUserInput,
  CreateContactUsInput,
  EmailChangeResponse,
  ForgotPasswordUserInput,
  LoginUserInput,
  MainResponse,
  MarketPlaceShowReq,
  MarketPlaceShowResponse,
  OtpLoginInputType,
  OTPResponse,
  PasswordChangeResponse,
  ProductCategoryShowNameReq,
  ProductCategoryShowNameResponse,
  ProductSearchGridShowReq,
  ProductSearchGridShowResponse,
  RegisterUserInput,
  ResetPasswordResponse,
  ResetPasswordUserInput,
  SendOtpCodeInputType,
  SocialLoginInputType,
  UpdateEmailUserInput,
  UpdateUserInput,
  User,
  UserMarketPlaceStatusReq,
  UserMarketPlaceStatusResponse,
  UserMarketPlaceUpdateReq,
  UserMarketPlaceUpdateResponse,
  UserMEInput,
  UserShowResponse,
  VerificationEmailUserInput,
  VerifyEmailInput,
  VerifyEmailResponse,
  VerifyForgotPasswordUserInput,
  VerifyOtpInputType,
} from "../../types";
import { API_ENDPOINTS } from "./api-endpoints";
import { HttpClient } from "./http-client";

//@ts-ignore
import { OTPVerifyResponse } from "@/types";
import {
  BankAccountCreate,
  BankAccountCreateResponse,
  BankAccountDelete,
  BankAccountDeleteResponse,
  BankAccountList,
  BankAccountListResponse,
  BankCardCreate,
  BankCardCreateResponse,
  BankCardDelete,
  BankCardDeleteResponse,
  BankCardList,
  BankCardListResponse,
  ConfigPaymentResponse,
  ConfirmPaymentIntentReq,
  ConfirmPaymentIntentResponse,
  CreatePaymentIntentReq,
  CreatePaymentIntentResponse,
  RetrievePaymentIntentReq,
  RetrievePaymentIntentResponse,
} from "types/customerFinance.model";
import {
  CommunicationMessagesListRequestModel,
  CommunicationSendMessageModel,
  MessageReplyRequestModel,
  MessagesDetailsRequestModel,
} from "types/communicationMessageModels";
import {
  BookSlotRequest,
  ServiceBookFreeSlotsRequest,
  ServiceBookFreeSlotsResponse,
  ServiceBookingSlotCancelRequest,
  ServiceBookingSlotDetailsRequest,
  ServiceBookingSlotDetailsResponse,
  ServiceBookingSlotsRequest,
  ServiceBookingSlotsResponse,
} from "types/serviceBooking.models";
import {
  FinancialTransactionResponse,
  financialTransactionsListRequest,
} from "types/financialTransactions.models";

class Client {
  users = {
    // me: () => HttpClient.get<User>(API_ENDPOINTS.USERS_ME),
    me_v2: (input: UserMEInput) =>
      HttpClient.post<UserShowResponse>(API_ENDPOINTS.USERS_ME, input),
    verifyEmail: (input: VerifyEmailInput) =>
      HttpClient.patch<VerifyEmailResponse>(
        API_ENDPOINTS.VERIFY_OTP_CODE,
        input
      ),
    update: (user: UpdateUserInput) =>
      HttpClient.put<User>(`${API_ENDPOINTS.USERS}/${user.id}`, user),
    login: (input: LoginUserInput) =>
      HttpClient.post<any>(API_ENDPOINTS.USERS_LOGIN, input),
    socialLogin: (input: SocialLoginInputType) =>
      HttpClient.post<AuthResponse>(API_ENDPOINTS.SOCIAL_LOGIN, input),
    sendOtpCode: (input: SendOtpCodeInputType) =>
      HttpClient.post<OTPResponse>(API_ENDPOINTS.SEND_OTP_CODE, input),
    verifyOtpCode: (input: VerifyOtpInputType) =>
      HttpClient.post<OTPVerifyResponse>(API_ENDPOINTS.VERIFY_OTP_CODE, input),
    OtpLogin: (input: OtpLoginInputType) =>
      HttpClient.post<AuthResponse>(API_ENDPOINTS.OTP_LOGIN, input),
    register: (input: RegisterUserInput) =>
      HttpClient.post<AuthResponse>(API_ENDPOINTS.USERS_REGISTER, input),
    forgotPassword: (input: ForgotPasswordUserInput) =>
      HttpClient.post<ResetPasswordResponse>(
        API_ENDPOINTS.USERS_FORGOT_PASSWORD,
        input
      ),
    userMarketPlaceUpdate: (input: UserMarketPlaceUpdateReq) =>
      HttpClient.post<UserMarketPlaceUpdateResponse>(
        API_ENDPOINTS.USER_MARKET_PLACE_STATUS_UPDATE,
        input
      ),
    userMarketPlaceStatus: (input: UserMarketPlaceStatusReq) =>
      HttpClient.post<UserMarketPlaceStatusResponse>(
        API_ENDPOINTS.USER_MARKET_PLACE_STATUS_STATUS,
        input
      ),
    verifyForgotPasswordToken: (input: VerifyForgotPasswordUserInput) =>
      HttpClient.post<PasswordChangeResponse>(
        API_ENDPOINTS.USERS_VERIFY_FORGOT_PASSWORD_TOKEN,
        input
      ),
    resetPassword: (input: ResetPasswordUserInput) =>
      HttpClient.patch<ResetPasswordResponse>(
        API_ENDPOINTS.USERS_RESET_PASSWORD,
        input
      ),
    changePassword: (input: ChangePasswordUserInput) =>
      HttpClient.post<PasswordChangeResponse>(
        API_ENDPOINTS.USERS_CHANGE_PASSWORD,
        input
      ),
    updateEmail: (input: UpdateEmailUserInput) =>
      HttpClient.post<EmailChangeResponse>(
        API_ENDPOINTS.USERS_UPDATE_EMAIL,
        input
      ),
    logout: () => HttpClient.post<boolean>(API_ENDPOINTS.USERS_LOGOUT, {}),
    deleteAddress: ({ id }: { id: string }) =>
      HttpClient.delete<boolean>(`${API_ENDPOINTS.USERS_ADDRESS}/${id}`),
    subscribe: (input: { email: string }) =>
      HttpClient.post<any>(API_ENDPOINTS.USERS_SUBSCRIBE_TO_NEWSLETTER, input),
    resendVerificationEmail: () => {
      return HttpClient.post<VerificationEmailUserInput>(
        API_ENDPOINTS.SEND_VERIFICATION_EMAIL,
        {}
      );
    },
  };

  customer = {
    getBusinessProfileServicesById: (
      input: BusinessProfileServicesRequestModel
    ) =>
      HttpClient.post<BusinessProfileServicesDetailsResponseModel>(
        API_ENDPOINTS.CUSTOMER_BUSINESS_PROFILE_SERVICES_BY_ID,
        input
      ),
    getBusinessProfileByWebUrl: (input: BusinessProfileRequestModel) =>
      HttpClient.post<BusinessProfileResponseModel>(
        API_ENDPOINTS.CUSTOMER_BUSINESS_PROFILE_BY_WEB_URL,
        input
      ),
    productCategoryShowName: (input: ProductCategoryShowNameReq) =>
      HttpClient.post<ProductCategoryShowNameResponse>(
        API_ENDPOINTS.CUSTOMER_PRODUCT_CATEGORY_NAME_SHOW,
        input
      ),
    productSearchGridShow: (input: ProductSearchGridShowReq) =>
      HttpClient.post<ProductSearchGridShowResponse>(
        `${API_ENDPOINTS.CUSTOMER_PRODUCT_SEARCH_GRID_SHOW}?page=${input.page}&items_per_page=${input.size}`,
        {
          marketplaceId: input.marketplaceId,
          level: input.level,
          category: input.category,
        }
      ),
    marketPlaceShow: (input: MarketPlaceShowReq) =>
      HttpClient.post<MarketPlaceShowResponse>(
        `${API_ENDPOINTS.CUSTOMER_MARKET_PLACE_SHOW}`,
        input
      ),
    contactUs: (input: CreateContactUsInput) =>
      HttpClient.post<any>(API_ENDPOINTS.USERS_CONTACT_US, input),

    getCommunicationMessagesList: (
      input: CommunicationMessagesListRequestModel
    ) =>
      HttpClient.post<any>(
        `${API_ENDPOINTS.CUSTOMER_COMMUNICATION_MESSAGES_LIST}?page=${input.page}&items_per_page=${input.size}`,
        {
          customer_email: input.customer_email,
        }
      ),
    sendMessage: (input: CommunicationSendMessageModel) =>
      HttpClient.post<MainResponse>(
        `${API_ENDPOINTS.CUSTOMER_COMMUNICATION_SEND_MESSAGES}`,
        input
      ),
    getMessagesDetails: (input: MessagesDetailsRequestModel) =>
      HttpClient.post<any>(
        `${API_ENDPOINTS.CUSTOMER_COMMUNICATION_MESSAGES_DETAILS}`,
        input
      ),
    sendMessageReply: (input: MessageReplyRequestModel) =>
      HttpClient.post<any>(
        `${API_ENDPOINTS.CUSTOMER_COMMUNICATION_MESSAGES_REPLY}`,
        input
      ),
    getBusinessBranches: (input: any) =>
      HttpClient.post<any>(
        `${API_ENDPOINTS.CUSTOMER_BUSINESS_BRANCHES}`,
        input
      ),
    getSellerOverallScore: (input: SellerOverallScoreRequest) =>
      HttpClient.post<any>(
        `${API_ENDPOINTS.CUSTOMER_SELLER_OVERALL_SCORE}`,
        input
      ),
    getSellerReviews: (input: SellerReviewsListRequest) =>
      HttpClient.post<any>(`${API_ENDPOINTS.CUSTOMER_SELLER_REVIEWS}`, input),

    sellerReviewScoreSubmit: (input: SellerReviewScoreRequest) =>
      HttpClient.post<any>(
        `${API_ENDPOINTS.CUSTOMER_SELLER_REVIEW_SCORE_SUBMIT}`,
        input
      ),
    getFinancialTransactionsList: (input: financialTransactionsListRequest) =>
      HttpClient.post<FinancialTransactionResponse>(
        `${API_ENDPOINTS.CUSTOMER_FINANCIAL_TRANSACTIONS_LIST}?page=${input.page}&items_per_page=${input.size}`,
        {
          marketplaceId: input.marketplaceId,
          start_date: input.start_date,
          end_date: input.end_date,
          customer_email: input.customer_id,
        }
      ),
  };

  customerFinance = {
    bankCardCreate: (input: BankCardCreate) =>
      HttpClient.post<BankCardCreateResponse>(
        API_ENDPOINTS.CUSTOMER_FINANCE_BANK_CARD_CREATE,
        input
      ),
    bankCardList: (input: BankCardList) =>
      HttpClient.post<BankCardListResponse>(
        API_ENDPOINTS.CUSTOMER_FINANCE_BANK_CARD_LIST,
        input
      ),
    bankCardDelete: (input: BankCardDelete) =>
      HttpClient.post<BankCardDeleteResponse>(
        API_ENDPOINTS.CUSTOMER_FINANCE_BANK_CARD_DELETE,
        input
      ),

    bankAccountCreate: (input: BankAccountCreate) =>
      HttpClient.post<BankAccountCreateResponse>(
        API_ENDPOINTS.CUSTOMER_FINANCE_BANK_ACCOUNT_CREATE,
        input
      ),
    bankAccountList: (input: BankAccountList) =>
      HttpClient.post<BankAccountListResponse>(
        API_ENDPOINTS.CUSTOMER_FINANCE_BANK_ACCOUNT_LIST,
        input
      ),
    bankAccountDelete: (input: BankAccountDelete) =>
      HttpClient.post<BankAccountDeleteResponse>(
        API_ENDPOINTS.CUSTOMER_FINANCE_BANK_ACCOUNT_DELETE,
        input
      ),

    createPaymentIntent: (input: CreatePaymentIntentReq) =>
      HttpClient.post<CreatePaymentIntentResponse>(
        API_ENDPOINTS.CUSTOMER_FINANCE_CREATE_PAYMENT_INTENT,
        input
      ),
    confirmPaymentIntent: (input: ConfirmPaymentIntentReq) =>
      HttpClient.post<ConfirmPaymentIntentResponse>(
        API_ENDPOINTS.CUSTOMER_FINANCE_CONFIRM_PAYMENT_INTENT,
        input
      ),
    retrievePaymentIntent: (input: RetrievePaymentIntentReq) =>
      HttpClient.post<RetrievePaymentIntentResponse>(
        API_ENDPOINTS.CUSTOMER_FINANCE_RETRIEVE_PAYMENT_INTENT,
        input
      ),
    paymentConfig: () =>
      HttpClient.post<ConfigPaymentResponse>(
        API_ENDPOINTS.CUSTOMER_FINANCE_PAYMENT_CONFIG,
        {}
      ),
  };
  serviceBooking = {
    getServiceBookingSlotsList: (input: ServiceBookingSlotsRequest) =>
      HttpClient.post<ServiceBookingSlotsResponse>(
        `${API_ENDPOINTS.CUSTOMER_SERVICE_BOOKING_SLOTS}?page=${input.page}&items_per_page=${input.size}`,
        {
          marketplaceId: input.marketplaceId,
          start_date: input.start_date,
          end_date: input.end_date,
          customer_email: input.customer_email,
        }
      ),
    serviceBookingSlotCancel: (input: ServiceBookingSlotCancelRequest) =>
      HttpClient.post<any>(
        `${API_ENDPOINTS.CUSTOMER_SERVICE_BOOKING_SLOTS_CANCEL}`,
        input
      ),
    getServiceBookingSlotDetails: (input: ServiceBookingSlotDetailsRequest) =>
      HttpClient.post<ServiceBookingSlotDetailsResponse>(
        `${API_ENDPOINTS.CUSTOMER_SERVICE_BOOKING_SLOTS_DETAILS}?page=1&items_per_page=5`,
        input
      ),
    getServiceBookingFreeSlots: (input: ServiceBookFreeSlotsRequest) =>
      HttpClient.post<ServiceBookFreeSlotsResponse>(
        `${API_ENDPOINTS.CUSTOMER_SERVICE_BOOKING_FREE_SLOTS}`,
        input
      ),
    getBookSlotFormCustomer: (input: BookSlotRequest) =>
      HttpClient.post<MainResponse>(
        `${API_ENDPOINTS.CUSTOMER_BOOKING_SLOT_FOR_CUSTOMER}`,
        input
      ),
  };
}

export default new Client();
