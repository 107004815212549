import { Rating } from "@mui/material";
import Button from "components/ui/button";
import { Form } from "components/ui/forms/form";
import Input from "components/ui/forms/input";
import TextArea from "components/ui/forms/text-area";
import { useModalAction, useModalState } from "provider/modal.context";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useSellerReviewScoreSubmit } from "service/hook/customer";
import { userStore } from "store";
import { MainResponse } from "types";
import { SellerReviewScoreRequest } from "types/business-profile-models";
import { MessageReplyRequestModel } from "types/communicationMessageModels";
import * as yup from "yup";

// import { useRegister } from '@/framework/user';

const registerFormSchema = yup.object().shape({
  review_title: yup.string().required("form:error-title-required"),
  review_content: yup.string().required("form:error-title-content"),
});

const CustomerReviewDialog = () => {
  const { t } = useTranslation();
  const { closeModal } = useModalAction();
  const { data } = useModalState();
  const { user } = userStore((state) => state);
  const [rate, setRate] = useState<number>(0);

  console.log(data);
  const { reset } = useForm<MessageReplyRequestModel>();

  const { mutate: mutateSellerReviewScore, isLoading } =
    useSellerReviewScoreSubmit();

  function onSubmit(requestData: SellerReviewScoreRequest) {
    const request: SellerReviewScoreRequest = {
      businessdetail_id: `${data?.item.businessDetailId}`,
      customer_id: user?.email ?? "",
      marketplaceId: "GBR",
      seller_score: requestData.seller_score,
      review_title: requestData.review_title,
      review_content: requestData.review_content,
    };

    mutateSellerReviewScore(request, {
      onSuccess: async (successData: MainResponse) => {
        if (successData.final_result_code === "000") {
          toast.success(successData.general_result);
          reset();
          closeModal();
        } else {
          toast.error(successData.general_result);
        }
      },
    });
  }

  return (
    <div
      className="m-auto rounded-md bg-light p-4 divide-y divide-gray-400"
      style={{ width: 700 }}
    >
      <Form<SellerReviewScoreRequest>
        onSubmit={onSubmit}
        validationSchema={registerFormSchema}
        serverError={null}
      >
        {({ register, formState: { errors } }) => (
          <>
            <div className="flex justify-between items-center">
              <h3 className="font-bold text-base">
                {t("common:text-write-review")}
              </h3>
            </div>
            <hr className="my-4" />
            <div className="flex gap-3">
              <span className="shrink-0 font-bold">
                {t("common:text-business")} :
              </span>
              <span className="ltr:ml-1 rtl:mr-1">
                {data?.item?.businessName}
              </span>
            </div>
            <div className="flex gap-3 my-3 items-center">
              <label className="block text-sm font-semibold leading-none text-body-dark align-middle">
                {t("common:text-overall-rate")} :
              </label>
              <Rating
                name="customer-rate"
                value={rate}
                precision={0.25}
                onChange={(event, newValue) => {
                  setRate(newValue ?? 0);
                }}
              />
            </div>
            <Input
              label={t("common:text-title")}
              {...register("review_title")}
              variant="outline"
              className="mb-3"
              error={t(errors.review_title?.message!)}
              maxLength={50}
            />
            <TextArea
              label={t("common:text-add-review")}
              {...register("review_content")}
              placeholder={t("common:text-review-content-water-mark")}
              variant="outline"
              className="mb-6"
              error={t(errors.review_content?.message!)}
              maxLength={500}
            />
            <label className="text-sm font-semibold leading-none text-body-dark">
              {t("common:text-review-submit-description")}
            </label>
            <div className="mt-3 flex gap-4 justify-center">
              <Button
                className="h-12 w-72 bg-red-400 hover:border-red-500 hover:bg-red-500"
                onClick={closeModal}
              >
                {t("common:text-cancel")}
              </Button>
              <Button
                className="h-12 w-72"
                loading={isLoading}
                disabled={isLoading}
              >
                {t("common:text-submit")}
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default CustomerReviewDialog;
