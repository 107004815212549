import React, { useEffect } from "react";
import Header from "../header";
import {
  useUser,
  useUserMarketPlaceStatus,
  useUserUpdateMarketPlace,
} from "../../service/hook/user";
import { authStore, userStore } from "../../store";
import {
  MarketPlaceShowReq,
  MarketPlaceShowResponse,
  UserMarketPlaceStatusReq,
  UserMarketPlaceStatusResponse,
  UserMarketPlaceUpdateReq,
  UserMarketPlaceUpdateResponse,
  UserShowResponse,
} from "../../types";
import { useLocation, useNavigate } from "react-router-dom";
import RootView from "../../views/root";
import { Routes } from "../../config/routes";
import { useMarketPlaceShow } from "../../service/hook/customer";
import pubStore from "../../store/pubStore";
import { toast } from "react-toastify";
import { Routes as routesPath } from "config/routes";

interface PublicLayoutComponentProps {
  children: React.ReactNode;
}

const PublicLayoutComponent = ({ children }: PublicLayoutComponentProps) => {
  const location = useLocation();

  const {
    mutate: getUser,
    isLoading: userIsLoading,
    serverError: userServerError,
    setServerError: usrSetServerError,
  } = useUser();

  const { accessToken, email, isAuthorized } = authStore((state) => state);
  const { user, marketPlaceItem } = userStore((state) => state);
  const { setMarketPlaceItems } = pubStore((state) => state);

  const {
    mutate: mutateMarketPlaceShow,
    isLoading: isLoadingMarketPlaceShow,
    serverError: serverErrorMarketPlaceShow,
    setServerError: setServerErrorMarketPlaceShow,
  } = useMarketPlaceShow();

  const {
    mutate: mutateUserMarketPlaceStatus,
    isLoading: isLoadingUserMarketPlaceStatus,
    serverError: serverErrorUserMarketPlaceStatus,
    setServerError: setServerErrorUserMarketPlaceStatus,
  } = useUserMarketPlaceStatus();

  useEffect(() => {
    // UserMarketPlaceStatus();
    getMarketPlaceShow();
  }, []);

  useEffect(() => {}, [accessToken]);

  const getMarketPlaceShow = () => {
    const input: MarketPlaceShowReq = {
      // marketplaceId: ''
    };
    if (!isLoadingMarketPlaceShow)
      mutateMarketPlaceShow(input, {
        onSuccess: (successData: MarketPlaceShowResponse) => {
          if (successData.final_result_code === "000") {
            setMarketPlaceItems(successData.captured_data);
          } else {
            toast.error(successData.general_result);
          }
        },
        onError: (error: Error) => {
          const {
            response: { data },
          }: any = error ?? {};

          toast.error(data?.general_result);

          if (data?.final_result_code === "001") {
            toast.error(data?.general_result[0]);
          }
        },
      });
  };

  const UserMarketPlaceStatus = () => {
    const input: UserMarketPlaceStatusReq = {
      email: user?.email ?? "",
    };
    if (!isLoadingMarketPlaceShow)
      mutateUserMarketPlaceStatus(input, {
        onSuccess: (successData: UserMarketPlaceStatusResponse) => {
          if (successData.final_result_code === "000") {
            // setMarketPlaceItems(successData.captured_data);
          } else {
            toast.error(successData.general_result);
          }
        },
        onError: (error: Error) => {
          const {
            response: { data },
          }: any = error ?? {};

          toast.error(data?.general_result);

          if (data?.final_result_code === "001") {
            toast.error(data?.general_result[0]);
          }
        },
      });
  };

  return (
    <div>
      {!location.pathname.includes(routesPath.business) && <Header />}

      <div>{children}</div>
    </div>
  );
};

export default PublicLayoutComponent;
