import { useMutation } from "@tanstack/react-query";
import client from "service/client";

export function useServiceBookingSlotsList() {
  const { mutate, isLoading } = useMutation(
    client.serviceBooking.getServiceBookingSlotsList
  );
  return { mutate, isLoading };
}

export function useServiceBookingSlotCancel() {
  const { mutate, isLoading } = useMutation(
    client.serviceBooking.serviceBookingSlotCancel
  );

  return { mutate, isLoading };
}

export function useServiceBookingSlotDetails() {
  const { mutate, isLoading } = useMutation(
    client.serviceBooking.getServiceBookingSlotDetails
  );

  return { mutate, isLoading };
}

export function useServiceBookingFreeSlots() {
  const { mutate, isLoading } = useMutation(
    client.serviceBooking.getServiceBookingFreeSlots
  );

  return { mutate, isLoading };
}

export function useBookSlotFormCustomer() {
  const { mutate, isLoading } = useMutation(
    client.serviceBooking.getBookSlotFormCustomer
  );

  return { mutate, isLoading };
}
