import Button from "components/ui/button";
import Card from "components/ui/cards/card";
import { DatePicker } from "components/ui/date-picker";
import Label from "components/ui/label";
import Loader from "components/ui/loader/loader";
import Pagination from "components/ui/pagination";
import moment from "moment";
import { useModalAction } from "provider/modal.context";
import Table from "rc-table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFinancialTransactionsList } from "service/hook/customer";
import { userStore } from "store";
import {
  FinancialTransaction,
  FinancialTransactionResponse,
  financialTransactionsListRequest,
} from "types/financialTransactions.models";
import { PageSize } from "utils/constants";
import Currency from "utils/constants/currency.json";

const FinancialTransactionsView = () => {
  const { t } = useTranslation("common");
  const { openModal } = useModalAction();
  const navigate = useNavigate();

  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [financialTransactions, setFinancialTransactions] = useState<
    FinancialTransaction[]
  >([]);

  const { user } = userStore((state) => state);

  const { mutate: mutateFinancialTransactionsList, isLoading } =
    useFinancialTransactionsList();

  const columns: any[] = [
    {
      title: t("table:table-id"),
      dataIndex: "transaction_id",
      key: "transaction_id",
      width: 80,
      align: "center",
    },
    {
      title: t("table:table-amount-paid"),
      dataIndex: "amount_paid",
      key: "amount_paid",
      width: 100,
      align: "center",
    },
    {
      title: t("table:table-amount-receive"),
      dataIndex: "amount_received",
      key: "amount_received",
      width: 100,
      align: "center",
    },
    {
      title: t("table:table-currency-type"),
      dataIndex: "currency_type",
      key: "currency_type",
      width: 80,
      align: "center",
      render: (value: string) => {
        return <div>{(Currency.currencyObj as any)[value]}</div>;
      },
    },
    {
      title: t("table:table-payment-status"),
      dataIndex: "payment_status",
      key: "payment_status",
      width: 80,
      align: "center",
    },
    {
      title: t("table:table-date"),
      dataIndex: "transaction_date",
      key: "transaction_date",
      width: 100,
      align: "center",
    },
    {
      title: t("table:table-time"),
      dataIndex: "_transaction_time",
      key: "_transaction_time",
      width: 100,
      align: "center",
    },
  ];

  const getFinancialTransactionsList = (pageNumber?: number) => {
    const input: financialTransactionsListRequest = {
      page: pageNumber ?? page,
      size: PageSize,
      customer_id: user?.email ?? "",
      marketplaceId: "GBR",
      start_date: startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : undefined,
      end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
    };

    mutateFinancialTransactionsList(input, {
      onSuccess: (successData: FinancialTransactionResponse) => {
        if (successData.final_result_code === "000") {
          setTotalCount(successData.total_count);
          setFinancialTransactions(successData.captured_data);
        }
      },
    });
  };

  useEffect(() => {
    getFinancialTransactionsList();
  }, []);

  const onPagination = (pageNumber: number) => {
    setPage(pageNumber);
  };

  return (
    <div className="mx-auto flex w-full max-w-7xl flex-col px-5 pb-20 md:flex-row md:pb-10 xl:px-8 xl:pb-14 2xl:px-14 mt-28">
      <Card className="self-stretch w-full min-h-screen overflow-hidden lg:min-h-0 mt">
        <div className="flex items-center justify-start mb-8 sm:mb-10">
          <div className="flex flex-col">
            <h1 className="text-lg font-semibold text-heading mb-4">
              {`${t("text-user")} / ${t("text-financial-transactions")}`}
            </h1>
            <div className="bg-cyan-100 px-7 py-4 rounded-md">
              <div className="flex flex-col gap-2">
                <h3 className="!text-lg font-semibold text-heading sm:text-xl">
                  {t("text-financial-transactions-description")}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full p-4">
          <div className="flex gap-4 w-full">
            <div className="w-1/2">
              <Label className="text-left">{"Start date"}</Label>
              <DatePicker
                className="w-full"
                selected={startDate}
                onChange={(date: any) => {
                  setStartDate(date);
                }}
                showYearDropdown
                showMonthDropdown
                dateFormat="yyy-MM-dd"
              />
            </div>
            <div className="w-1/2">
              <Label className="text-left">{"End date"}</Label>
              <DatePicker
                className="w-full"
                selected={endDate}
                onChange={(date: any) => {
                  setEndDate(date);
                }}
                showYearDropdown
                showMonthDropdown
                dateFormat="yyy-MM-dd"
              />
            </div>
          </div>
        </div>

        <div
          className="mb-4 text-start px-4"
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <div
            className="mb-4 text-start"
            style={{
              display: "flex",
              justifyContent: "start",
            }}
          >
            <Button
              onClick={() => {
                getFinancialTransactionsList(1);
              }}
              loading={isLoading}
              disabled={isLoading}
            >
              {"Search"}
            </Button>
          </div>
        </div>
        <>
          {isLoading ? (
            <Loader
              className="!h-full flex-1"
              text={t("common:text-loading")}
            />
          ) : (
            <>
              <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
                <Table
                  columns={columns}
                  emptyText={t("table:empty-table-data")}
                  data={financialTransactions}
                  rowKey="service_booking_record_id"
                  scroll={{ x: 900 }}
                />
              </div>
              <div className="flex items-center justify-end">
                <Pagination
                  total={totalCount}
                  current={page}
                  pageSize={PageSize}
                  onChange={onPagination}
                  showLessItems
                />
              </div>
            </>
          )}
        </>
      </Card>
    </div>
  );
};

FinancialTransactionsView.authenticationRequired = true;
export default FinancialTransactionsView;
